<template>
  <div id="auth">
    <h1>
      Please Check In
    </h1>
    <form
      ref="checkIn"
      action="#">
      <em>
        Sign in with your email and password
      </em>
      <div class="flex-r">

        <div class="flex-c center">

          <v-input
            name="email"
            placeholder="Email"
            autocomplete="username"
          ></v-input>
          <v-input
            name="passphrase"
            type="password"
            placeholder="Passphrase"
            autocomplete="current-password"
          ></v-input>

        </div>

        <div class="flex-c center icon6x">

          <playIcon
              :size="96"
              class="primary"
              title="Register"
              @click="login"
          />

        </div>
      </div>
    </form>
    <hr>
    <em>
      Sign up by re-typing your email and password
    </em>
    <form
      ref="signUp"
      autocomplete="off"
      action="#">
      <div class="flex-r">

        <div class="flex-c center">

          <v-input
            name="email"
            placeholder="Email"
            autocomplete="off"
          ></v-input>
          <v-input
            name="passphrase"
            type="password"
            placeholder="Passphrase"
            autocomplete="new-password"
          ></v-input>

        </div>

        <div class="flex-c center icon6x">

          <fingerprintIcon
            class="primary"
            title="Register as new user"
            @click="signup"
          />

        </div>
      </div>
    </form>
  </div>
</template>

<script>
  import {
    REGISTER_USER,
    LOGIN,
  } from '@/store/graph/types'
  export default {
    name: "Auth",
    methods: {
      login() {
        const form = this.$refs.checkIn
        this.$store.dispatch(
          LOGIN,
          {
            email: form.email.value,
            passphrase: form.passphrase.value
          }
        )
          .then(response => {
            let success = response?.data?.length
            if (!success) {
              this.flashWarning('Sign in failed', {timeout: 4000})
            } else {
              this.flashSuccess('You now have editing privileges', {timeout: 4000})
            }
          })
      },
      signup() {
        const form = this.$refs.signUp

        // todo: show invalid field value if emails, or passphrases do not match
        this.$store.dispatch(
          REGISTER_USER,
          {
            email: form.email.value,
            passphrase: form.passphrase.value
          }
        )
          .then(response => {
            let success = response?.data?.length
            if (!success) {
              this.flashWarning('Sign up failed', {timeout: 4000})
            } else {
              this.flashSuccess('Welcome as a new member. You now have editing privileges', {timeout: 4000})
            }
          })
      }
    }
  }
</script>

<style scoped>
  #auth {
    max-width: 400px;
  }
  .login {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .inputs {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  .submit {
    .font-size: 48px;
  }
  .flex-c {
    justify-content: space-evenly;
  }
</style>
