<template>
  <div class="urlSafeStringInput">
    <div class="column p1">

      <div
          v-if="showCharRange"
          class="charRangeInfo message">
        <div>
          Url-safe strings can contain the following characters:
          <span class="chars">0-9a-zA-Z</span> and
          <span class="chars">_.</span><span style="font-family: sans-serif">~</span><span class="chars">-</span>
        </div>
      </div>

      <div class="validatedInput column">
        <vInput
            :value="newUrlSafeString"
            :placeholder="placeholder"
            :disabled="isDisabled"
            @onChange="validateString"
        />
        <div>
          <span
              v-if="validationErrorMessage"
              class="validationErrorMessage error message"
          >
            {{ validationErrorMessage }}
            (<span class="chars">{{conflictingChars}}</span>)
          </span>
        </div>
      </div>

      <vButton
          v-if="newUrlSafeString"
          :disabled="validationErrorMessage || isDisabled"
          :label="`Confirm name: '${ newUrlSafeString }'`"
          :onClick="emitNewStringValue"
      ></vButton>

    </div>
  </div>
</template>

<script>
import { listConflictingCharacters, validate } from "@/components/lib/validation"

  export default {
    name: "vUrlSafeStringInput",
    props: {
      isDisabled: {
        type: Boolean,
        default: false
      },
      isConfirmed: {
        type: Boolean,
        default: true
      },
      placeholder: {
        type: String,
        default: 'New url-safe string value'
      },
      showCharRange: {
        type: Boolean,
        default: false,
      }
    },
    data() {
      return {
        newUrlSafeString: '',
        validationErrorMessage: false,
        conflictingChars: '',
      }
    },
    watch: {
      isConfirmed(oldValue, newValue) {
        const confirmedNow = oldValue === false && newValue
        if (confirmedNow) {
          this.reset()
        }
      }
    },
    methods: {
      assertIsUrlSafe(string) {
        return validate(string, 'isUrlSafe')
      },
      emitNewStringValue() {
        this.$emit('onChange', this.newUrlSafeString)
      },
      getErrorMessage() {
        const isPlural = this.conflictingChars.length === 1
        return `unsafe character${isPlural ? 's' : ''} found`
      },
      listUrlUnsafeCharacters(string) { return listConflictingCharacters(string, 'isUrlSafe') },
      reset() {
        this.resetError()
        this.newUrlSafeString = ''
      },
      resetError() {
        this.validationErrorMessage = false
        this.conflictingChars = ''
      },
      validateString(newValue) {
        this.newUrlSafeString = newValue.replaceAll(' ', '_')
        const stringIsUrlSafe = this.assertIsUrlSafe(this.newUrlSafeString) || !newValue.length
        if  (!stringIsUrlSafe) {
          this.conflictingChars = this.listUrlUnsafeCharacters(this.newUrlSafeString).join(',')
          this.validationErrorMessage = this.getErrorMessage()
        } else {
          this.resetError()
        }
      },
    }
  }
</script>

<style scoped>

</style>
