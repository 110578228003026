<template>
  <a :href="emailUrl">
    <span class="tag reportBug">
      Report bugs
    </span>
  </a>
</template>

<script>
  export default {
    name: "vReportBug",
    computed: {
      bugReportEmail () { return this.$store.state.bugReportEmail },
      emailUrl() {
        return `mailto:${this.bugReportEmail}?subject=App bug&body=Hej,\\\\\\\\nI found the following bug in TextDB.\\\\\\\\n\\\\\\\\nHere is what i did:\\\\\\\\n\\\\\\\\n\\\\\\\\nHere is what I expected:\\\\\\\\n\\\\\\\\n\\\\\\\\nHere is what happened:\\\\\\\\n\\\\\\\\n`
      },
    },
  }
</script>

<style scoped>
  .reportBug {
    display: inline;
    cursor: pointer;
  }
  .reportBug * {
    cursor: pointer;
  }
</style>
