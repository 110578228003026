import { render, staticRenderFns } from "./vNotFound.vue?vue&type=template&id=6591c2f6&scoped=true&"
import script from "./vNotFound.vue?vue&type=script&lang=js&"
export * from "./vNotFound.vue?vue&type=script&lang=js&"
import style0 from "./vNotFound.vue?vue&type=style&index=0&id=6591c2f6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6591c2f6",
  null
  
)

export default component.exports