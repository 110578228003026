<template>
  <div class="people">
    <h2>People</h2>
    <div
        v-if="documents.length"
        class="list"
    >
      <div
        v-for="(person, index) in staff"
        :key="index"
        class="item"
      >
        <vPerson
            :collectionName="collectionName"
            :documentId="person.id"
        ></vPerson>
      </div>
    </div>
    <p v-else>
      No people are listed here yet...
    </p>
    <h2>Alumni</h2>
    <div
        v-if="documents.length"
        class="list"
    >
      <div
          v-for="(person, index) in alumni"
          :key="index"
          class="item"
      >
        <vPerson
            :collectionName="collectionName"
            :documentId="person.id"
        ></vPerson>
      </div>
    </div>
    <div class="flex-c center">
      <div
        v-if="user"
        class="labeledButton"
      >
        <div
          @click="createJsonDocument"
          class="icon icon6x"
        >
          <CardAccountDetailsIcon
              :size="48"
              style="font-size: 48px!important;"
              title="Add a Person"
          ></CardAccountDetailsIcon>
        </div>
        <span @click="createJsonDocument">
          Add a Person
        </span>
      </div>
    </div>
  </div>
</template>

<script>
  import vPerson                 from './vPerson'
  import JsonCollectionMixin     from "../mixins/documentCollections/json/JsonCollectionMixin";
  import { byDescendingVersion } from "@/components/DocumentCollection/lib/sortFunctions"
  export default {
    name: 'vPeople',
    components: {
      vPerson
    },
    mixins: [
      JsonCollectionMixin
    ],
    props: {
      collectionName: {
        type: String,
        default: 'people',
      },
      componentName: {
        type: String,
        default: 'vPerson',
        validator: (val) => val === 'vPerson'
      },
      listComponentName: {
        type: String,
        default: 'vPeople',
        validator: (val) => val === 'vPeople'
      },
    },
    data() {
      return {
        defaultContent: {
          name: "",
          affiliation: "Aarhus University",
          imageUrl: "https://upload.wikimedia.org/wikipedia/commons/b/bb/Paul_Klee_WI_%28In_Memoriam%29_1938.jpg"
        }
      }
    },
    computed: {
      staff: {
        get() {
          return this.sortedDocuments
              .filter(doc => !this.getPersonIsAlumni(doc.id))
        }
      },
      alumni: {
        get() {
          return this.sortedDocuments
              .filter(doc => this.getPersonIsAlumni(doc.id))
        }
      }
    },
    methods: {
      getPersonIsAlumni(documentId) {
        return this.getPerson(documentId).isAlumni ?? false
      },
      getPersonVersion(documentId) {
        let version
        const versions = this.getVersionsByDocumentId(documentId)
            .sort(byDescendingVersion)
        if (versions.length) {
          version = versions[0] // latest
        }
        return version
      },
      getPerson(id) {
        let person
        const version = this.getPersonVersion(id)
        person = JSON.parse(version.content)
        return person
      }
    }
  }
</script>

<style scoped>
  .list {
    grid-gap: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .item {
    width: 100%;
  }
</style>
