<template>
  <div class="list full-width mv-1">
    <div
      class="headings flex-r spread"
      v-if="headings.length"
    >
      <span
        class="heading"
        v-for="(heading, index) in headings"
        :key="`heading${index}`"
      >
        {{heading}}
      </span>
    </div>
    <hr>
    <div class="items">
      <div
        v-if="sortable"
      ></div>
      <template
        v-for="(item, index) in items"
      >
        <component
          :class="{odd: index % 2}"
          v-bind:is="itemComponentName"
          :key="`item${index}`"
          :collectionName="collectionName"
          :documentName="item.name"
        ></component>
        <hr
          :key="`ruler${index}`"
        >
      </template>
    </div>
  </div>
</template>

<script>
  export default {
    name: "vList",
    props: {
      collectionName: {
        type: String,
        required: true
      },
      headings: {
        type: Array,
      },
      itemComponentName: {
        type: String,
        required: true
        // todo: validator function namespace allowing local imports a la getAvailableTypesMixin
      },
      items: {
        type: Array
      },
      sortable: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      isNotLastItem(index) {
        return index < this.items.length - 1
      },
    }
  }
</script>

<style scoped>
  .list .odd {
    background-color: #dee6e8;
  }
</style>
