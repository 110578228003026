<template>
  <div class="image">
    <div class="frame">
      <img
        :src="src"
        :alt="alt"
      >
      <div class="subtitle">
        <slot></slot>
      </div>
    </div>
    <div style="clear: both"></div>
  </div>
</template>

<script>
  export default {
    name: "vImage",
    props: {
      src: {
        type: String,
        required: true
      },
      alt: {
        type: String,
        default: ''
      },
      isOdd: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        /*
          non-panorama images are limited to this proportion of the available width for their shorter side
         */
        maxImageProportion: 0.4,
      }
    },
    mounted() {
      this.$nextTick(() => {
        window.addEventListener('resize', () => {
          this.styleImage()
        })
      })

      const img = this.$el.querySelector('img')
      img.onload = this.onImageLoaded

    },
    methods: {
      onImageLoaded() {
        //requestAnimationFrame(this.styleImage)
      },
      styleImage() { // todo: decompose. determine textflow situation first
        const frame = this.$el.querySelector('.frame')
        const img = this.$el.querySelector('img')
        const isPortrait = img.clientHeight > img.clientWidth

        if (isPortrait) {

          frame.style.maxWidth = `${(1 - this.maxImageProportion) * 100}%`

        } else {

          const columnWidth = img.parentElement.offsetWidth
          const isPanorama = img.clientWidth >= 2 * img.clientHeight
          const imageCanCoverColumn = img.clientWidth >= columnWidth

          const fitToColumn = isPanorama && imageCanCoverColumn
          if (fitToColumn) {
            img.style.height = '100%'
          } else {
            img.style.maxHeight = `${this.maxImageProportion * 100}vh`
          }

          img.style.width = 'auto'

          const textIsSquashed = img.clientWidth / columnWidth >= 0.75
          if (textIsSquashed) {
            img.style.width = '100%'
          }

        }
        frame.style.float = this.isOdd ? 'left' : 'right'
        frame.style[`margin${this.isOdd ? 'Right' : 'Left'}`] = '1rem'
        frame.style.marginTop = '1rem'
        frame.style.marginBottom = '1rem'
        //frame.style.maxWidth = img.offsetWidth + 'px'

      }
    }
  }
</script>

<style scoped>
  .frame {
    background-color: transparent;
  }
  .subtitle {
    position: relative;
    z-index: 3;
    margin-top: -.5rem;
    padding: 0 0.2rem;
    word-wrap: break-word;
    font-style: italic;
    line-height: 1.4rem;
  }
  img {
    clear: both;
    max-width: 100%;
  }
</style>
