<template>

  <input
    v-if="editable"
    :value.sync="value"
    :placeholder="placeholder"
    :type="type"
    :name="name"
    :autocomplete="autocomplete"
    @keyup="onInput"
    @blur="onInput"
    @keyup.esc="revert"
  >

</template>

<script>
  export default {
    name: "vInput",
    props: {
      autocomplete: { // https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#autofilling-form-controls%3A-the-autocomplete-attribute
        type: String,
      },
      editable: {
        type: Boolean,
        default: true
      },
      name: {
        type:String,
      },
      passwordVisible: {
        type: Boolean,
        default: false
      },
      placeholder: {
        type: String,
        default: 'Name'
      },
      type: {
        type: String,
        default: 'text'
      },
      value: {
        type: String,
      },
    },
    data() {
      return {
        originalValue: this.value
      }
    },
    methods: {
      revert() {
        this.$emit('onCancel', this.originalValue)
      },
      onInput(e) {
        const value = e.target.value
        const isEnterKey = (e.code || e.key === 'Enter') || (e.keyCode === 13)
        if (isEnterKey) {
          this.$emit('onConfirm', value)
        }
        this.$emit('onChange', value)
      },

    }
  }
</script>

<style scoped>

</style>
