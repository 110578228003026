<template>
  <v-container fluid fill-height>
    <v-layout column align-center fluid>
      <v-flex class="top" text-xs-center xs12>
        <v-card
          class="elevation22"
          v-trianglify="{
            cell_size: 35,
            x_colors: parchmentColorArray,
            bColor: '#D7CCC8',
            width: 900,
            height: 900
          }"
        >
          <v-card-text>
            <div class="frame">
              <img src="assets/attributed/Dangerous Cliff Edge.jpg" style="width:100%;">
            </div>
            <v-container fill-height fluid>
              <v-layout fill-height>
                <v-flex xs12 align-end flexbox>
                  <div class="textbox black--text">
                    <p class="title">
                      You have reached
                      <em>the very edge</em> of the Internet
                    </p>
                    <p>404</p>
                    <p>
                      {{$route.path}} was not found.
                    </p>
                  </div>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
        </v-card>
      </v-flex>
      <v-flex class="bottom" xs10><!-- todo: componentify: AttributedImage, ImageWindow (with attribution) -->
        <a href="/attribution" style="color: black;">
          <div class="frame" >
            <img src="/images/Spawn of the Stars by Sofyan Syarief.png">
          </div>
          <span
            class="attribution"
            @click="$router.push('/attribution')"
          >
          <v-icon>link</v-icon>
          by courtesy of Sofyan Syarief
        </span>
        </a>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
  import {mapState} from 'vuex'
  export default {
    name: 'NotFound',
    computed: {
      ...mapState([
        'parchmentColorArray'
      ])
    }
  }
</script>
<style scoped>
  .textbox {
    background-color: rgba(255, 255, 255, 0.5);
    padding:  1em;
    margin:  1em;
    border-radius: 1em;
  }
</style>
