<template>
  <div
      @click="onClick"
      class="flex-c center">
    <div class="labeledButton">
      <div
          class="icon"
          :style="iconStyle"
      >
        <component :is="icon"
            :size="iconSizePx"
            :style="iconStyle"
            title="Add an report"
        ></component>
      </div>
      <span :style="labelStyle">
        {{ label }}
      </span>
    </div>
  </div>
</template>

<script>
  import { isNumber } from '../../../lib/getVariableType'
  export default {
    name: "vLabeledIconButton",
    props: {
      icon: {
        type: String,
        default: 'PlayIcon',
        validate: (iconComponentName) => iconComponentName.endsWith('Icon')
      },
      iconSizePx: {
        type: Number,
        default: 48,
        validate: (sizePx) => isNumber(sizePx) && 12 <= sizePx
      },
      fontSize: {
        type: String,
        default: '1rem',
        validate: (sizeCSS) => !sizeCSS.includes(';')
      },
      label: {
        type: String,
        required: true
      },
    },
    computed: {
      iconStyle() { return {fontSize: `${this.iconSizePx}px!important`} },
      labelStyle() { return {fontSize: this.fontSize} }
    },
    methods: {
      onClick() { this.$emit('onClick')},
    }
  }
</script>

<style scoped>
  .icon::before { display: none!important; }
  .icon {
    color: #003d73;
  }
  .labeledButton {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
</style>
