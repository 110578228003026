<template>
  <div class="person card">
    <vJsonObject
      :editing="user && editing"
      class="jsonObject"
      :class="{editing: editing}"
      :collectionName="collectionName"
      :listComponentName="listComponentName"
      :componentName="$options.name"
      :documentId="documentId"
      :fields="fields"
    >
      <div class="rendered">
        <div class="columns">
          <div class="frame" :id="person.id">
            <div
                class="portrait"
                :style="portraitStyle" :title="`image of ${person.name}`"></div>
          </div>
          <div class="details">
            <div
              v-if="user"
              class="actions"
            >
              <div
                  class="edit icon2x"
                  @click="editing=!editing"
              >
                <leadPencilIcon></leadPencilIcon>
              </div>
              <div class="delete icon">
                <vDeleteDocumentButton
                  @onConfirmed="deleteDocument"
                ></vDeleteDocumentButton>
              </div>
            </div>
            <div class="title">
              <span>{{person.title}}</span>
              <span v-if="person.title">,</span>
              <span v-if="person.role"> {{person.role}}</span>
            </div>
            <div class="name">{{person.name}}</div>
            <div class="contactDetails">
              <em class="affiliation">
                {{person.affiliation}}
              </em>
              <hr>
              <div class="email">
                <span>{{person.email}}</span>
              </div>
              <div
                  v-if="person.phoneNumber"
                  class="phone">
                <div
                    class="number">
                  {{person.phoneNumber}}
                </div>
              </div>
              <div
                  v-if="person.building && person.room"
                  class="location">
                <a class="building" target="_blank"
                   :href="`https://international.au.dk/about/contact/?b=${person.building}`">
                  office:
                  <span>{{person.building}}</span>,
                  <span>{{person.room}}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

    </vJsonObject>
  </div>
</template>

<script>
  import JsonDocumentMixin from '../mixins/documentCollections/json/JsonDocumentMixin'
  import * as actions     from "../store/graph/types"
  export default {
    name: "vPerson",
    mixins: [
      JsonDocumentMixin
    ],
    data() {
      return {
        editing: false,
        fields: [
          {
            name: "name",
            defaultValue: "Full Name",
            type: "text",
            required: true
          },
          {
            name: "role",
            options: [
              'PI',
              'co-PI',
              'HOPE Senior Researcher',
              'Leader of Etnographic Research at HOPE',
              'Postdoc',
              'Research Assistant'
            ],
            type: "select"
          },
          {
            name: "title",
            type: "select",
            options: [
              "Assistant Professor",
              "Associate Professor",
              "Ph.D.",
              "Professor",
              "Research Assistant"
            ]
          },
          {
            name: "affiliation",
            required: true,
            type: "select",
            options: [
              "Aarhus University",
              "Technical University of Denmark",
              "University of Copenhagen",
            ]
          },
          {
            name: "isAlumni",
            label: "list as alumni",
            type: "boolean",
            value: false,
          },
          {
            name: "email",
            type: "email"
          },
          {
            name: "phoneNumber",
            label: "phone number",
            type: "text",
          },
          {
            type: 'ruler'
          },
          {
            name: "imageUrl",
            label: 'portrait url',
            type: "url",
            validator: val => val
          },
          {
            name: "imageZoom",
            label: "portrait Zoom",
            min: 50,
            value: 100,
            max: 500,
            type: "range",
            labelSuffix: '%',
          },
          {
            name: "imagePosX",
            label: "horizontal nudge",
            type: "range",
            labelSuffix: '%',
          },
          {
            name: "imagePosY",
            label: "vertical nudge",
            type: "range",
            labelSuffix: '%',
          },
        ],
      }
    },
    computed: {
      person: {
        get() {
          return this.object
        },
      },
      portraitStyle() {
        let style = {}

        const posX = this.person.imagePosX !== undefined ? `${( 100 - this.person.imagePosX )}%` : 'center'
        const posY = this.person.imagePosY  !== undefined ? `${( 100 - this.person.imagePosY )}%` : 'center'
        const zoom = this.person.imageZoom  !== undefined ? `auto ${ this.person.imageZoom }%` : 'cover'

        style = {
          backgroundImage: `url("${this.person.imageUrl || 'https://upload.wikimedia.org/wikipedia/commons/b/bb/Paul_Klee_WI_%28In_Memoriam%29_1938.jpg'}")`,
          backgroundPosition: `${posX} ${posY}`,
          backgroundSize: zoom
        }

        return style
      }
    },
    methods: {
      deleteDocument() {
        this.$store.dispatch(actions.DELETE_DOCUMENT, {
          collectionName: this.collectionName,
          type: 'json',
          documentId: this.documentId
        })
      },
    }
  }
</script>

<style scoped>
  .rendered {
    display: flex;
    align-content: center;

  }
  .columns {
    display: grid;
    grid-template-columns: calc(150px + 1rem + 2px) 1fr; /* frame offsets */
  }
  .columns>* {
    position: relative;
  }
  .person {
    text-align: left;
    background-color: transparent;
    border-radius: .2rem;
    display: grid;
    grid-template-columns: auto auto;
    height: 100%;
  }
  .frame {
    border: 1px solid rgba(0,0,0,0.2);
    border-radius: 4px;
    padding: .5em;
  }
  .portrait {
    background-repeat: no-repeat;
    background-size: cover;
    width: 150px;
    height: 100%;
  }
  .details {
    margin-left: 1rem;
  }
  .location {
    margin-top: .5em;
    cursor: pointer;
  }
  .actions {
    display: flex;
    flex-direction: row;
    align-content: flex-start;
    justify-content: space-between;
  }
  .icon::before {
    display: none;
    width: 0;
  }
  .jsonObject {
    padding: 1rem;
    background-color: white;
  }
  .actions {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
</style>
