<template>
  <div
    class="userRole"
    @click="showPermissions = !showPermissions"
  >

      <span
        class="tag"
        :style="style"
      >{{label}}</span>

    <div
      v-if="showPermissions"
      class="permissions"
    >
      <p>
        As {{userRole}} you can:
      </p>

      <div
        class="permission"
        v-for="item in permissions"
        :key="item"
      >
        <input
          type="checkbox"
          :name="item"
          disabled
          checked
          :value="true">
        <label :for="item">{{item}}</label>
      </div>

      <hr>

      <vDataResponsibility/>

    </div>
  </div>
</template>

<script>
  export default {
    name: "vUserRole",
    props: {
      plural: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        showPermissions: false
      }
    },
    computed: {
      label() {
        return this.plural ? this.userRole + 's' : this.userRole
      },
      style() {
        const radius = 4
        let style = {
          borderBottomLeftRadius: `${radius}px`,
          borderBottomRightRadius: `${radius}px`,
        }
        if (this.showPermissions) {
          style.borderBottomLeftRadius = '0'
          style.borderBottomRightRadius = '0'
        }
        return style
      }
    }
  }
</script>

<style scoped>
  .userRole {
    display: inline;
    cursor: pointer;
  }
  .userRole * {
    background-color: #4c4c4c!important;
    color: white;
    cursor: pointer;
  }
  .permissions {
    background-color: #4c4c4c;
    color: white;
    padding: 1em;
    border-radius: 4px;
    margin-bottom: .5em;
  }
</style>
