import Vue from 'vue'
import App from './App'
import components from './components'
import GetGlobalsMixin from "./mixins/GetGlobalsMixin"
import store           from './store'
import router from './router'

import AsyncComputed from 'vue-async-computed'
import VueClipboard from 'vue-clipboard2'
import VueFlashMessage from 'vue-flash-message'

import '@/plugins/Dayjs'

import 'vue-material-design-icons/styles.css'
import './assets/AUStyle.css'
import 'vue-datetime/dist/vue-datetime.css'
import 'vue-flash-message/dist/vue-flash-message.min.css'

console.clear()

Vue.config.productionTip = false

Vue.mixin(GetGlobalsMixin)

Vue.use(AsyncComputed)
Vue.use(VueClipboard)
Vue.use(VueFlashMessage)

// register all components globally
Object.keys(components).forEach(name => {
  Vue.component(name, components[name])
})

/* eslint-disable no-new */
new Vue({
  render: h => h(App),
  icons: {
    iconfont: 'mdi' // todo: needed?
  },
  store,
  router
}).$mount('#app')

