<template>
  <button
      class="button"
      @click="onClick"
  >{{ label }}</button>
</template>

<script>
export default {
  name: "vCreateNewVersionButton",
  props: {
    label: {
      type: String,
      required: true
    },
    onClick: {
      type: Function,
      required: true
    }
  },
}
</script>

<style scoped>
</style>
