<template>
  <div class="navigation">
    <div class="nav nav--site-nav theme--dark">
      <img
        class="logo"
        src="../assets/bss_logo.png"
        alt="Logo of the School of Business and Social Sciences at Aarhus Universitet"
        @click="push('/bss')"
      >
      <div class="nav__site">
        <div class="nav__items">
          <a
            class="nav__item"
            :class="{'nav__item--active': route.path === $route.path}"
            v-for="(route, index) in menuRoutes"
            :key="index"
            @click="push(route.path)"
          >
            {{route.name}}
            <span
              v-if="route.comingSoon"
              class="tag">coming soon...</span>
          </a>

          <a
            class="nav__item"
            v-if="!user"
            @click="showSignInForm=true"
          >
            <fingerprintIcon
              class="primary"
              title="Sign In"
            />
          </a>

          <template v-if="user">

            <a
              class="nav__item"
              title="Sign off"
              @click="logout"
            >
              <fingerprintOffIcon
                class="active"
                title="Sign Off"
              />
            </a>

            <a
              class="nav__item"
              title="Edit document collections"
              @click="gotoCollections"
            >
              Document Collections
            </a>

          </template>

        </div>
      </div>
    </div>
    <div class="section-title">
      <div class=" large-10 medium-10 columns">
        <h1
          class="association"
          @click="push('/ps')"
        >
          Department of Political Science
        </h1>
      </div>
      <div class="large-2 medium-2 small-2 columns seal text-right">

      </div>
    </div>
    <div
      class="flex-c center"
      v-if="showSignInForm && !user">
      <div
        class="overlay"
        @click="showSignInForm = false"
      ></div>
      <vAuth
        class="form"
      ></vAuth>
    </div>

  </div>
</template>

<script>
import {routes} from '../router'
import * as actions from '../store/graph/types'

export default {
  name: 'vNavigation',
  data() {
    return {
      routes,
      menuRoutes: routes.filter(r => r.inMenu),
      showSignInForm: false,
    }
  },
  computed: {
    user() {
      return this.$store.state.user
    }
  },
  methods: {
    push(url) {
      this.showSignInForm = false
      if (url !== this.$route.path) {
        this.$router.push(url)
        this.$store.commit('SET_ACTIVE_ROUTE', url)
      }
    },
    logout() {
      this.showSignInForm = false
      this.$store.dispatch(actions.LOGOUT)
    },
    gotoCollections() {
      this.$router.push('/documents')
    },
  }
}
</script>

<style scoped>
  .logo {
    height: 4rem;
    margin-right: 2rem;
  }
  .tag {
    position: absolute;
    margin-left: .5em;
    z-index: 1;
    font-size: 60%;
    background-color: #ffffd1;
    color: black;
    border-radius: 1em;
    padding: 0 .5em;
    transform: rotate(30deg);
  }
  .section-title {
    border-bottom: 3px solid #002546;
  }
  .association {
    font-size: 100%;
    padding: var(--spacing-m4) var(--spacing-0) 0 var(--spacing-0);
    margin: 0;
  }
  .overlay {
    height: 100%;
    width: 100%;
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 75px;
    background-color: white;
    background-color: rgba(255,255,255, 0.95);
    overflow-x: hidden; /* Disable horizontal scroll */
    transition: 0.5s;
  }
  .form {
    z-index: 1;
    top: 10rem;
    padding: 2rem;
    background-color: white;
    box-shadow: white 0 0 6rem 2rem;
  }

</style>
