<template>
  <div class="repoFile">
    <vMarkdown
      v-if="fileIsReady"
      :md="file"
    ></vMarkdown>
    <vLoading
      v-else
      :title="filePath"
    ></vLoading>
  </div>
</template>

<script>
  import repoFileMixin from "../mixins/repoFileMixin"
  export default {
    name: "vRepoFile",
    mixins: [
      repoFileMixin
    ],
    computed: {
      fileIsReady() {
        return this.file
      }
    }
  }
</script>

<style scoped>

</style>
